import Particles from "react-tsparticles";
import * as images from "../assets/img/icons";

export default function Background() {
  return (
    <Particles
      id="tsparticles"
      options={{
        fpsLimit: 60,
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: "bubble",
            },
            resize: true,
          },
          modes: {
            bubble: { distance: 250, size: 20 },
          },
        },
        background: { color: "#f7f9fb" },
        particles: {
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 20,
          },
          color: { value: "#ffffff" },
          shape: {
            type: "image",
            image: [
              ...Object.keys(images).map((i) => ({
                src: images[i],
                width: 100,
                height: 100,
              })),
            ],
          },
          opacity: {
            anim: {
              enable: true,
              speed: 1,
              sync: false,
              opacity_min: 0,
            },
            value: 1,
            random: true,
          },
          size: {
            value: 15,
            random: true,
            anim: { enable: true, speed: 1, opacity_min: 0, sync: false },
          },
          move: {
            direction: "none",
            enable: true,
            outModes: "out",
            speed: 0.5,
            straight: false,
            attract: { enable: false, rotateX: 600, rotateY: 600 },
          },
        },
      }}
    />
  );
}
