import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  FormErrorMessage,
  Heading,
  chakra,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useStore from "../../store";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Register() {
  const { resetPassword } = useStore((state) => state.auth);
  const [params, setParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    const success = await resetPassword(params.get("oobCode"), data.password);
    if (success) navigate("/login", { replace: true });
  };

  return (
    <>
      <Heading fontSize="2xl" fontWeight="semibold" mb="6">
        Reset Password
      </Heading>

      <chakra.form onSubmit={handleSubmit(onSubmit)} noValidate mb="3">
        <Stack spacing="6">
          <FormControl isInvalid={errors.password}>
            <FormLabel fontWeight="400">Password</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="--------"
                {...register("password", { required: "This field is required" })}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            colorScheme="primary"
            size="lg"
            fontSize="md"
            isLoading={isSubmitting}
          >
            Reset password
          </Button>
        </Stack>
      </chakra.form>
    </>
  );
}
