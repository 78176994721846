import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Textarea,
  Button,
  Select,
} from "@chakra-ui/react";
import { useDatabaseUpdateMutation } from "@react-query-firebase/database";
import { nanoid } from "nanoid";
import { useForm } from "react-hook-form";
import { rtDatabase } from "../../databaseConfig";
import useStore from "../../store";
import { equalTo, get, orderByChild, query, ref, serverTimestamp } from "firebase/database";
import toast from "react-hot-toast";

export default function NoteDrawer({ isOpen, onClose, title, id = null, defaultValues = {} }) {
  const {
    tags,
    auth: { user },
  } = useStore((state) => state);
  const dbRef = ref(rtDatabase, `notes/${!!id ? id : nanoid()}`);
  const mutation = useDatabaseUpdateMutation(dbRef);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues });

  const onSubmit = async (note) => {
    if (!id) {
      const notes = await get(
        query(ref(rtDatabase, `notes/`), orderByChild("author"), equalTo(user.uid))
      );
      console.log(notes.val(), !!notes);
      if (!!notes.val() && Object.keys(notes.val()).length >= 10) {
        toast.error("Maximum number of notes : 10");
        return;
      }
      note.createdAt = serverTimestamp();
      note.modifiedAt = serverTimestamp();
      note.important = false;
      note.author = user.uid;
      mutation.mutate(note);
      toast.success("Note created 👍");
    } else if (id) {
      note.modifiedAt = serverTimestamp();
      mutation.mutate(note);
      toast.success("Note updated 👍");
    }
    reset();
    onClose();
  };
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="sm"
      preserveScrollBarGap={true}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>

        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)} id="new-note-form" noValidate>
            <FormControl isInvalid={errors.title} isRequired mb="4">
              <FormLabel htmlFor="title">Title</FormLabel>
              <Input
                placeholder="Note's title"
                {...register("title", {
                  required: "This field is required",
                  maxLength: { value: 80, message: "Maximum length is 80 characters" },
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.text} isRequired mb="4">
              <FormLabel htmlFor="text">Title</FormLabel>
              <Textarea
                placeholder="Note's text"
                {...register("text", {
                  required: "This field is required",
                  maxLength: { value: 500, message: "Maximum length is 500 characters" },
                })}
              />
              <FormErrorMessage>{errors.text && errors.text.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.tag} isRequired mb="4">
              <FormLabel htmlFor="tag">Tag</FormLabel>
              <Select
                {...register("tag", { required: "This field is required" })}
                id="country"
                placeholder="Select tag"
              >
                {tags.map((tag) => (
                  <option value={tag.id} key={tag.label}>
                    {tag.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.tag && errors.tag.message}</FormErrorMessage>
            </FormControl>
          </form>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose} isLoading={isSubmitting}>
            Cancel
          </Button>
          <Button colorScheme="brand" form="new-note-form" type="submit" isLoading={isSubmitting}>
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
