import { Flex, Spinner } from "@chakra-ui/react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useStore from "../store";

const AuthCheckRoute = ({ needAuth }) => {
  const authenticated = useStore((state) => state.auth.authenticated);
  const location = useLocation();

  if (authenticated === null)
    return (
      <Flex w="full" minH="100vh" alignItems="center" justifyContent="center">
        <Spinner thickness="4px" color="brand.500" speed="0.55s" size="xl" />
      </Flex>
    );
  switch (needAuth) {
    case true:
      return authenticated ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
    case false:
      return !authenticated ? (
        <Outlet />
      ) : (
        <Navigate to="/notes" state={{ from: location }} replace />
      );
    default:
      return "Loading...";
  }
};
export default AuthCheckRoute;
