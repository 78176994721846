import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  FormErrorMessage,
  Heading,
  Text,
  Flex,
  Divider,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useStore from "../../store";

export default function Login() {
  const { login, signInWithGoogle } = useStore((state) => state.auth);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    await login(data);
  };

  return (
    <>
      <Heading fontSize="2xl" fontWeight="semibold" mb="6">
        Login
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing="6">
          <FormControl isInvalid={errors.email}>
            <FormLabel fontWeight="400">Email address</FormLabel>
            <Input
              placeholder="example@gmail.com"
              {...register("email", { required: "This field is required" })}
              type="email"
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password}>
            <Flex alignItems="center" justifyContent="space-between" mb="2">
              <FormLabel m="0" fontWeight="400">
                Password
              </FormLabel>
              <Button variant="link">
                <Link to="/forgot-password">Forgot password?</Link>
              </Button>
            </Flex>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="--------"
                {...register("password", { required: "This field is required" })}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            colorScheme="primary"
            size="lg"
            fontSize="md"
            isLoading={isSubmitting}
          >
            Sign in
          </Button>
        </Stack>
      </form>
      <Text fontSize="sm" mt="5" mb="3" textAlign="center">
        Not registred? <Link to="/register">Create an account</Link>
      </Text>
      <Flex align="center">
        <Divider />
        <Text padding="2"> or </Text>
        <Divider />
      </Flex>
      <Button
        onClick={signInWithGoogle}
        variant="outline"
        colorScheme="gray"
        w="full"
        height="12"
        leftIcon={<FcGoogle />}
        mt="2"
      >
        Sign in with Google
      </Button>
    </>
  );
}
