import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { HiPlus } from "react-icons/hi";
import SelectCategory from "./SelectCategory";
import SelectTag from "./SelectTag";
import Search from "./Search";
import NoteDrawer from "../NoteDrawer";

export default function Topbar() {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <Flex
      flexWrap="wrap"
      gap
      columnGap="8"
      rowGap="2"
      mb="4"
      flexDir={{ base: "column", sm: "row" }}
    >
      <Button rightIcon={<HiPlus />} colorScheme="brand" variant="solid" onClick={onOpen}>
        New Note
      </Button>
      <SelectTag />
      <SelectCategory />
      <Search />
      <NoteDrawer title="Create a note" isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
}
