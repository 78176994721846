import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { HiSelector, HiOutlineStar } from "react-icons/hi";
import { GrHomeOption } from "react-icons/gr";
import useStore from "../../../store";
// import { FiTrash } from "react-icons/fi";

export default function SelectCategory() {
  const importantOnly = useStore((state) => state.importantOnly);

  return (
    <Menu autoSelect={false}>
      <MenuButton
        variant="select"
        as={Button}
        leftIcon={importantOnly ? <HiOutlineStar /> : <GrHomeOption />}
        rightIcon={<HiSelector />}
      >
        {importantOnly ? "Important" : "My Notes"}
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<GrHomeOption />}
          onClick={() => useStore.setState({ importantOnly: false })}
        >
          My Notes
        </MenuItem>
        <MenuItem
          icon={<HiOutlineStar />}
          onClick={() => useStore.setState({ importantOnly: true })}
        >
          Important
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
