import { Box, Flex, Text, Heading, Tag, useDisclosure, Icon } from "@chakra-ui/react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { BiTrashAlt, BiPencil, BiTrash } from "react-icons/bi";
import NoteButton from "./NoteButton";
import NoteDrawer from "../NoteDrawer";
import useStore from "../../../store";
import { rtDatabase } from "../../../databaseConfig";
import {
  useDatabaseRemoveMutation,
  useDatabaseUpdateMutation,
} from "@react-query-firebase/database";
import { ref } from "firebase/database";
import TimeAgo from "react-timeago";
import toast from "react-hot-toast";

export default function Note({ note }) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const dbRef = ref(rtDatabase, `notes/${note.id}`);
  const importantNote = useDatabaseUpdateMutation(dbRef);
  const deleteNote = useDatabaseRemoveMutation(dbRef, {
    onSuccess() {
      toast.success(
        <Flex alignItems="center" columnGap="1">
          Note deleted <Icon as={BiTrash} color="red.400" fontSize="20" />{" "}
        </Flex>
      );
    },
  });

  const tags = useStore((state) => state.tags);
  let { id, title, text, important, createdAt, modifiedAt, tag } = note;
  tag = tags.find((tag) => tag.id === note.tag);
  return (
    <Box pos="relative" mb="10" rounded="lg" bg="white" px="4" pt="6" shadow="base">
      <Flex pos="absolute" top="-6" right="0" columnGap="2">
        <NoteButton
          icon={BiPencil}
          color="blue.700"
          onClick={() => {
            onOpen({ title, text, tag });
          }}
        />
        <NoteButton icon={BiTrashAlt} color="red.700" onClick={() => deleteNote.mutate()} />
        <NoteButton
          icon={important ? AiFillStar : AiOutlineStar}
          active={important}
          color="yellow.400"
          onClick={() => importantNote.mutate({ important: !important })}
        />
      </Flex>
      <Tag mb="2" bg={tag.color} py="0.5" px="2" color="white" fontSize="md">
        {tag.label}
      </Tag>
      <Heading mb="2" fontSize="xl" fontWeight="semibold">
        {title}
      </Heading>
      <Text mb="4">{text}</Text>
      <Box borderTop="1px" borderColor="gray.200" pt="3" pb="6">
        <Text>
          {createdAt === modifiedAt ? "Created " : "Updated "}
          <TimeAgo
            date={modifiedAt - 2000}
            formatter={(value, unit, suffix) => {
              if (unit.includes("second")) return "just now";
              else return `${value} ${unit} ${suffix}`;
            }}
          />
        </Text>
      </Box>
      <NoteDrawer
        title="Update a note"
        id={id}
        defaultValues={{ title, text, tag: tag.id }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
}
