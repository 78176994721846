import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import { HiLogout } from "react-icons/hi";
import Masonry from "react-masonry-css";
import Note from "./Note";
import Topbar from "./Topbar";
import { rtDatabase } from "../../databaseConfig";
import useStore from "../../store";
import filterNotes from "../../utils/filterNotes";
import { useDatabaseSnapshot } from "@react-query-firebase/database";
import { ref, orderByChild, equalTo, query } from "firebase/database";

const breakpointColumnsObj = {
  default: 3,
  1280: 2,
  768: 1,
};

export default function Index() {
  const { logout, user } = useStore((state) => state.auth);
  const dbRef = query(ref(rtDatabase, `notes/`), orderByChild("author"), equalTo(user.uid));

  const notes = useDatabaseSnapshot(
    "notes",
    dbRef,
    { idField: "id", subscribe: true },
    {
      cacheTime: 60000,
      select(snapshot) {
        let data = [];
        snapshot.forEach((doc) => {
          data.push({ id: doc.key, ...doc.val() });
        });
        return data;
      },
    }
  );
  const { search, importantOnly, selectedTag } = useStore((state) => state);

  if (notes.isLoading)
    return (
      <Flex w="full" minH="100vh" alignItems="center" justifyContent="center">
        <Spinner thickness="4px" color="brand.500" speed="0.55s" size="xl" />
      </Flex>
    );
  if (notes.isError) return <h2>{notes?.error?.message}</h2>;

  const data = filterNotes(notes, search, importantOnly, selectedTag);

  return (
    <Box
      position="relative"
      flex
      flexDirection="column"
      alignItems="center"
      px={{ base: "6", xl: "14" }}
      py={{ base: "4", xl: "10" }}
    >
      <Topbar />
      <Box position="relative" flex="1">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid mt-2 pt-10"
          columnClassName="my-masonry-grid_column"
        >
          {data?.map((note) => (
            <Note key={note.id} note={note} />
          ))}
        </Masonry>
      </Box>
      <Button
        pos="fixed"
        rightIcon={<HiLogout />}
        colorScheme="red"
        variant="solid"
        right={{ base: "6", xl: "14" }}
        bottom={{ base: "3" }}
        onClick={logout}
      >
        Logout
      </Button>
    </Box>
  );
}
