import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  FormErrorMessage,
  Heading,
  Text,
  Flex,
  Divider,
  chakra,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useStore from "../../store";

export default function Register() {
  const { forgotPassword } = useStore((state) => state.auth);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    const success = await forgotPassword(data.email);
    if (success) navigate("/login", { replace: true });
  };

  return (
    <>
      <Heading fontSize="2xl" fontWeight="semibold" mb="6">
        Forgot Password
      </Heading>

      <chakra.form onSubmit={handleSubmit(onSubmit)} noValidate mb="3">
        <Stack spacing="6">
          <FormControl isInvalid={errors.email}>
            <FormLabel fontWeight="400">Email address</FormLabel>
            <Input
              placeholder="example@gmail.com"
              {...register("email", { required: "This field is required" })}
              type="email"
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            colorScheme="primary"
            size="lg"
            fontSize="md"
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Stack>
      </chakra.form>

      <Flex align="center">
        <Divider />
        <Text padding="2"> or </Text>
        <Divider />
      </Flex>
      <Text mt="1" textAlign="center">
        Return to <Link to="/">Login</Link>
      </Text>
    </>
  );
}
