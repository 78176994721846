import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import create from "zustand";
import { auth } from "./databaseConfig";
import produce from "immer";
import toast from "react-hot-toast";
import getErrorMessage from "./utils/getErrorMessage";

const store = (set) => ({
  search: "",
  selectedTag: null,
  importantOnly: false,
  tags: [
    {
      label: "Simple Note",
      color: "blue.400",
      id: "simple-note",
    },
    {
      label: "Todo",
      color: "red.400",
      id: "todo",
    },
    {
      label: "Working On",
      color: "orange.400",
      id: "working-on",
    },
    {
      label: "Done",
      color: "green.400",
      id: "done",
    },
  ],
  notes: {
    data: [],
    state: null,
  },
  auth: {
    authenticated: null,
    setAuthenticated: (authenticated) =>
      set(
        produce((state) => {
          state.auth.authenticated = authenticated;
        })
      ),

    user: null,
    setUser: (user) =>
      set(
        produce((state) => {
          state.auth.user = user;
        })
      ),

    // sign up with email & password
    signup: async ({ email, password }) => {
      try {
        await createUserWithEmailAndPassword(auth, email, password, {});
        // toast.success(`A confirmation mail has been sent to ${res.user.email}`);
        toast.success(`Account created successfully 😀`);
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    },

    // login with email and password
    login: async ({ email, password }) => {
      try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        console.log(res.user);
        toast.success(`Welcome 🎉`);
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    },

    // logout
    logout: async () => {
      try {
        await signOut(auth);
        toast.success("👋", { icon: "👋" });
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    },

    // sign in with google
    signInWithGoogle: async () => {
      try {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider).then(() => toast.success(`Welcome 🎉`));
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    },

    // forgot password
    forgotPassword: async (email) => {
      try {
        await sendPasswordResetEmail(auth, email);
        toast.success(
          <div>
            <p>A password reset link was sent.</p>
            <p>Click the link in the email to create a new password.</p>
          </div>,
          {
            duration: 7500,
          }
        );
        return true;
      } catch (e) {
        toast.error(getErrorMessage(e));
        return false;
      }
    },

    // reset password
    resetPassword: async (oobCode, password) => {
      try {
        await confirmPasswordReset(auth, oobCode, password);
        toast.success("Password changed successfully 👍");
        return true;
      } catch (e) {
        toast.error(getErrorMessage(e));
        return false;
      }
    },
  },
});

const useStore = create(store);

export default useStore;
