import {
  Flex,
  // IconButton,
  Input,
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuList,
  Icon,
} from "@chakra-ui/react";
import { HiSearch } from "react-icons/hi";
import useStore from "../../../store";

export default function Search() {
  const search = useStore((state) => state.search);

  return (
    <Flex
      flexGrow="1"
      alignItems="center"
      rounded="lg"
      bg="white"
      h="10"
      px="6"
      shadow="base"
      columnGap="3"
    >
      <Icon as={HiSearch} fontSize={20} color="gray.400" />
      <Input
        variant="unstyled"
        py="2"
        placeholder="Search by title..."
        sx={{ caretColor: "gray" }}
        value={search}
        onChange={(e) => useStore.setState({ search: e.target.value })}
      />
      {/* <Menu autoSelect={false}>
        <MenuButton
          as={IconButton}
          icon={<Icon as={HiSwitchVertical} fontSize={20} color="gray.400" />}
          variant="ghost"
          height="9"
        />
        <MenuList>
          <MenuItem>Sort A - Z</MenuItem>
          <MenuItem>Sort Z - A</MenuItem>
          <MenuItem>Sort oldest to newest</MenuItem>
          <MenuItem>Sort newest to oldest</MenuItem>
        </MenuList>
      </Menu> */}
    </Flex>
  );
}
