import { extendTheme } from "@chakra-ui/react";
import "@fontsource/quicksand/300.css";
import "@fontsource/quicksand/400.css";
import "@fontsource/quicksand/500.css";
import "@fontsource/quicksand/600.css";
import "@fontsource/quicksand/700.css";

import Menu from "./Menu";
import Button from "./Button";
import Input from "./Input";

let theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        lineHeight: "1em",
        color: "gray.600",
      },
      body: {
        bg: "none",
      },
      a: {
        color: "brand.500",
        _hover: {
          color: "brand.700",
          textDecoration: "none",
        },
      },
    },
  },
  fonts: {
    heading: "Quicksand, sans-serif",
    body: "Quicksand, sans-serif",
  },
  colors: {
    brand: {
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
  },
  components: {
    Menu,
    Button,
    Input,
    Textarea: {
      defaultProps: {
        focusBorderColor: "brand.500",
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: "brand.500",
      },
    },
  },
});

theme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: theme.colors.brand,
  },
};

export default theme;
