import { Box, Flex, Image } from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";
import logo from "../../assets/img/logo.png";

export default function Login() {
  return (
    <Flex w="full" minH="100vh" pt="10" alignItems="center" flexDirection="column">
      <Link to="/login">
        <Box
          bg="white"
          w="28"
          h="28"
          p="5"
          shadow="lg"
          transitionDuration="200ms"
          _hover={{ transform: "translateY(-5px)", shadow: "xl" }}
          rounded="full"
          mb="10"
        >
          <Image w="full" h="full" objectFit="cover" src={logo} alt="" />
        </Box>
      </Link>
      <Box bg="white" p="8" shadow="lg" rounded="md" w={{ base: "full", sm: "96" }}>
        <Outlet />
      </Box>
    </Flex>
  );
}
