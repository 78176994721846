import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { HiSelector } from "react-icons/hi";
import { TbCircles } from "react-icons/tb";
import { GiPlainCircle } from "react-icons/gi";
import useStore from "../../../store";

export default function SelectTag() {
  const tags = useStore((state) => state.tags);
  const selectedTag = useStore((state) => state.selectedTag);

  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={Button}
        leftIcon={
          selectedTag ? (
            <Icon as={GiPlainCircle} color={selectedTag.color} />
          ) : (
            <TbCircles color="gray.500" />
          )
        }
        rightIcon={<HiSelector />}
        variant="select"
      >
        {selectedTag ? selectedTag.label : "All"}
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<TbCircles color="gray.500" />}
          onClick={() => useStore.setState({ selectedTag: null })}
        >
          All
        </MenuItem>
        {tags.map((tag, i) => (
          <MenuItem
            key={i}
            icon={<Icon as={GiPlainCircle} color={tag.color} />}
            onClick={() => useStore.setState({ selectedTag: tag })}
          >
            {tag.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
