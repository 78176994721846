import { Icon, Box } from "@chakra-ui/react";

export default function NoteButton({ icon, color, active = false, onClick = () => {} }) {
  return (
    <Box
      as="button"
      _hover={{ color: color }}
      className="transition hover:scale-125"
      onClick={onClick}
    >
      <Icon as={icon} fontSize="xl" color={active ? color : "inherit"} />
    </Box>
  );
}
