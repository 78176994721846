const filterNotes = (notes, search, importantOnly, selectedTag) => {
  let data =
    search.length <= 0
      ? notes?.data
      : notes?.data?.filter((d) => d.title.toLowerCase().includes(search.toLowerCase()));

  data = !importantOnly ? data : data?.filter((d) => d.important);

  data = !selectedTag ? data : data?.filter((d) => d.tag === selectedTag.id);

  data = data.sort((a, b) => parseInt(b.modifiedAt) - parseInt(a.modifiedAt));

  return data;
};

export default filterNotes;
