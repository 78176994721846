const Button = {
  // Styles for the base style
  baseStyle: {
    fontWeight: 400,
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    link: {
      _hover: {
        color: "brand.500",
        textDecoration: "none",
      },
    },
    "icon-scale": {
      p: 0,
      w: "fit-content",
      h: "fit-content",
      transitionDuration: "200ms",
      transformOrigin: "50% 50%",
      _hover: {
        transform: "scale(1.25)",
      },
    },
    select: {
      w: { base: "100%", sm: "3xs" },
      bg: "white",
      shadow: "base",
      textAlign: "left",
      _hover: {
        shadow: "md",
      },
      _active: {
        shadow: "md",
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
};

export default Button;
