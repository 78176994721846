import { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { Toaster } from "react-hot-toast";
import { useQueryClient } from "react-query";

import { auth } from "./databaseConfig";
import { Background } from "./components";
import { ForgotPassword, AuthLayout, Login, Register, ResetPassword, Notes } from "./pages";
import AuthCheckRoute from "./utils/AuthCheckRoute";
import useStore from "./store";

function App() {
  const { setAuthenticated, setUser } = useStore((state) => state.auth);
  const queryClient = useQueryClient();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) setAuthenticated(true);
      else {
        setAuthenticated(false);
        queryClient.clear();
      }
    });
    return () => unsubscribe();
  }, []);
  return (
    <>
      <Background />
      <Router>
        <Routes>
          <Route element={<AuthCheckRoute needAuth={false} />}>
            <Route path="/" element={<AuthLayout />}>
              <Route path="/" element={<Navigate to="login" replace />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Route>
          <Route element={<AuthCheckRoute needAuth={true} />}>
            <Route path="/notes" element={<Notes />} />
          </Route>
        </Routes>
      </Router>
      <Toaster toastOptions={{ position: "top-center", duration: 5000 }} />
    </>
  );
}

export default App;
