const Menu = {
  parts: ["menu", "list", "item", "button"],

  // The base styles for each part
  baseStyle: {
    list: {
      p: 1,
      shadow: "md",
    },
    item: {
      p: 2.5,
      rounded: "md",
    },
  },
  // The size styles for each part
  sizes: {},
  // The variant styles for each part
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
};

export default Menu;
